import React, { useEffect, useState } from 'react'
import { Col, Form, FormControl, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'

import styles from '../JournalDetails/JournalDetails.module.scss';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AxiosService from '../../utils/AxiosService';
import { toast } from 'react-toastify';
import { CircleLoader } from '../../components/Loader/CommonLoader';

import ArrayInput from './ArrayInput';
import HtmlInput from './HtmlInput';


const JournalMetadata = () => {

    const apiService = new AxiosService();
    const [journalForms, setJournalForms] = useState([])
    const [formValues, setFormValues] = useState({});
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [addValidated, setAddValidated] = useState(false);
    const history = useHistory();
    const [isBlocking, setIsBlocking] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);

    function getAdminGuidelinesData() {
        apiService.getJournalCreateForm().then((res) => {
            setJournalForms(res);
        }).catch(err => {
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setAddValidated(true);
            toast.warning("Please fill in all the required fields.", {
                autoClose: 3000,
            })
        } else {
            setIsSaveLoading(true)
            apiService.saveJournalForm(formValues).then((res) => {
                toast.success("Saved successfully", {
                    autoClose: 3000,
                })

                if (res.message === "created successfully") {
                    history.push("/admin-dashboard")
                }
                setIsSaveLoading(false);
            }).catch(err => {
                setIsSaveLoading(false);
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                })
            })
        }
        setIsBlocking(false);
        setShowModal(false);
        history.push(nextLocation.pathname);
    }
    const handleChange = (event) => {
        const obj = { ...formValues }
        event.preventDefault();
        setIsBlocking(event.target.value.length > 0);
        const { name, value } = event.target;
        if (obj.hasOwnProperty(name)) {
            obj[name] = value;
        } else {
            obj[name] = value;
        }
        setFormValues(obj)
    }

    const handleArrayChange = (attribute, value) => {
        const obj = { ...formValues }
        if (obj.hasOwnProperty(attribute)) {
            obj[attribute] = value;
        } else {
            obj[attribute] = value;
        }
        setFormValues(obj)
    }
    useEffect(() => {
        // Block navigation if there are unsaved changes
        const unblock = history.block((location) => {
            if (isBlocking) {
                setShowModal(true);
                setNextLocation(location);
                return false;
            }
            return true;
        });

        return () => {
            unblock();
        };
    }, [isBlocking, history]);
    const handleCancel = () => {
        setShowModal(false);
    };

    const handleDiscardAndLeave = () => {
        history.push(nextLocation.pathname);
        setIsBlocking(false);
        setShowModal(false);
    };
    useEffect(() => {
        getAdminGuidelinesData()
    }, [])
    return (
        <>
            <div className='mb-3'>
                <button className={styles.customLink} onClick={() => history.push("/admin-dashboard")}>
                    <FontAwesomeIcon icon={faArrowLeftLong} className='mr-2' />
                    Back to admin dashboard
                </button>
            </div>
            <div className='card p-3'>
                <div className='mt-3 border-bottom'><h4>Add a New Journal</h4>
                    <p>Enter the journal metadata and URLs </p></div>
                <Form noValidate validated={addValidated} onSubmit={handleSubmit}>
                    <Row className='mt-3'>
                        {
                            journalForms.length === 0 ? <Col className="text-center my-4"><CircleLoader /></Col> : journalForms.map((rules, i) => (
                                <Form.Group as={Col} md={rules.dataType === "HTML" ? "12" : "6"} controlId={`validationCustom${i}`} key={i}>
                                    <Form.Label className='mr-2'>{rules.name}{rules.mandatory && <span className="text-danger">*</span>}</Form.Label>
                                    {
                                        rules.toolTip && <OverlayTrigger
                                            key="journalKeywords"
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-journal`}>
                                                    {rules.toolTip}
                                                </Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon icon={faInfoCircle} />
                                        </OverlayTrigger>
                                    }
                                    {rules.dataType === "HTML" ? <HtmlInput rules={rules} handleArrayChange={handleArrayChange} intialValue={rules.value} />
                                        : rules.dataType === "Array" ? <ArrayInput rules={rules} handleArrayChange={handleArrayChange} intialValue={rules.value} isDisable={false} setIsBlocking={setIsBlocking}/> : rules.type === "textarea" ?
                                            <FormControl
                                                required={rules.mandatory}
                                                aria-label={rules.name}
                                                placeholder={rules.name}
                                                name={rules.attribute}
                                                defaultValue={rules.value}
                                                aria-describedby={`basic-addon${i}`}
                                                as="textarea"
                                                onChange={(e) => handleChange(e)}
                                            /> : rules.dataType === "Number" ? <FormControl
                                                required={rules.mandatory}
                                                aria-label={rules.name}
                                                placeholder={rules.name}
                                                name={rules.attribute}
                                                defaultValue={rules.value}
                                                aria-describedby={`basic-addon${i}`}
                                                type={rules.dataType}
                                                onChange={(e) => handleChange(e)}
                                                min={rules.min}
                                                max={rules.max}
                                                step="any"
                                            /> : rules.hasOwnProperty("source") ? <Form.Control as="select"
                                                onChange={(e) => handleChange(e)}
                                                name={rules.attribute}
                                                defaultValue={rules.value}>
                                                <option value=" " hidden>Please select</option>
                                                {rules.source.map((item, i) => <React.Fragment key={i}> <option value={item}>{item}</option></React.Fragment>)}
                                            </Form.Control> : <FormControl
                                                required={rules.mandatory}
                                                aria-label={rules.name}
                                                placeholder={rules.name}
                                                name={rules.attribute}
                                                defaultValue={rules.value}
                                                aria-describedby={`basic-addon${i}`}
                                                type={rules.type}
                                                onChange={(e) => handleChange(e)}
                                            />
                                    }
                                    <Form.Control.Feedback type='invalid'>
                                        {`Please provide a valid ${rules.name.toLowerCase()}.`}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            ))
                        }
                    </Row>
                    <div className='my-3'>
                        {
                            !isSaveLoading ? <button type='submit'>Save Journal </button> : (
                                <button disabled={isSaveLoading}>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Saving...</span>
                                </button>)
                        }
                    </div>
                </Form>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    Warning!
                </Modal.Header>
                <Modal.Body>
                    <p>You are leaving the document you are working on. </p>
                    <button onClick={handleCancel} className="w-100 mt-2 btnOutline">Cancel</button>
                    <button onClick={handleDiscardAndLeave} className="w-100 mt-2">Discard changes & leave</button>
                </Modal.Body>


            </Modal>
        </>
    )
}

export default JournalMetadata
