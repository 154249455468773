import { faCloudArrowDown, faExternalLink, faInfoCircle, faLink, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import {  Col, Form, FormControl, Modal, Row} from "react-bootstrap";
import { toast } from "react-toastify";
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import AxiosService from "../../../../../utils/AxiosService";
import GuidelineTab from "../StepTwoForm/guidelineTab";
import styles from "./StepOneForm.module.scss";


const SteOneForm = ({
	referenceType, setReferenceType, manuscriptId, 
	handelError,language, setLanguage, templateType, 
	journalId, handleSave, setTitle, setAbstract, title, 
	abstract, manuscriptType, setManuscriptType, 
	handleMandatoryCheck, mandatoryFieldSet, studyType, 
	setStudyType, referenceCheck, setReferenceCheck, 
	articleTitle, setArticleTitle, stepStatus, setStepOneInfo,
	anonymisedPeerReviewCheck, setAnonymisedPeerReviewCheck,
	readPolicyCheck, setReadPolicyCheck, setReadValidationChecklist
}) => {
	const apiService = new AxiosService();
	const [manuscriptTypeOptions, setManuscriptTypeOptions] = useState([])
	const [studyTypesOptions, setStudyTypesOptions] = useState([])
	const [rscPolicies, setRscPolicies] = useState([])
	const [showNote, setShowNote] = useState(false)
	const [showGenericNote, setShowGenericNote] = useState(false)
	const [originalArticle, setOriginalArticle] = useState("")
	const [helpText, setHelpText]=useState([]);
	const [show, setShow] = useState(false);
	const [languageOptions, setLanguageOptions] = useState([])
	const [titleLength, setTitleLength] = useState(-1)

	const getHelpHext = () => {
		apiService.getHelpText(journalId).then(res=>{
			setHelpText(res.result)
			setLanguageOptions(res.languages)
			if(language==="") {
				setLanguage(res?.languages[0].code)
			}
			if(res.result.length>0){
				setReferenceType(res.result[0].name)
			}
		}).catch(err=>{
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}
	
	const handleSelect = (e) => {
		const {value} = e.target
		setManuscriptType(value);
		const obj = manuscriptTypeOptions.filter(op => op.name === e.target.value)
		if(!obj[0]?.mainArticle && value !=="" && templateType !== "Generic") {
			setShowNote(true)
		}
		handleMandatoryCheck(e.target.value);
	}

	const handleHelpText = () => {
		setShow(true);
	}

	const handleStudyType = (e) => {
		setStudyType(e.target.value)
	}

	const getManuscriptTypes = () => {
		apiService.getDropdownOptions("manuscript", journalId, manuscriptId).then(res=> {
			if(res.result){
				setManuscriptTypeOptions(res.result);
				res.result.filter(op =>{
					if(op.mainArticle) setOriginalArticle(op.name)
				} )
			}
		})
	}

	const getData =() => {
		apiService.getStepOneDetails(manuscriptId).then(
			res => { 
				const data= res.result;
				setStepOneInfo({...data, templateType: templateType})
				setArticleTitle(data?.manuscriptTitle)
				setManuscriptType(data?.manuscriptType)
				setStudyType(data?.studyType)
				setReferenceType(data?.referenceFormat)
				setLanguage(data?.language)
				if(data?.manuscriptTitle.length > 0) {
					setTitleLength(data.manuscriptTitle.length)
				}
				if(data?.referenceCheck === "yes" || data?.referenceCheck === "no") {
					setReferenceCheck(data?.referenceCheck)
				}
				if(data?.readPoliciesAndComply) {
					setReadPolicyCheck(true)
				} else {
					setReadPolicyCheck(false)
				}
				if(data?.anonymizedPeerReview) {
					setAnonymisedPeerReviewCheck(true)
				} else {
					setAnonymisedPeerReviewCheck(false)
				}
				if(data?.readingChecklist) {
					setReadValidationChecklist(true)
				} else {
					setReadValidationChecklist(false)
				}
				handleMandatoryCheck(data?.manuscriptType)
			}).catch(e=> {
				toast.error(e?.response?.data?.message, {autoClose: 3000})
			})
	}

	const getRSCPolicies = () => {
		apiService.getDropdownOptions("journalPolicy", journalId, manuscriptId).then(res=> {
			if(res.result){
				setRscPolicies(res.result);
			}
		}).catch((err) => {
			toast.error(err?.response?.data?.message)
		})
	}


	const getStudyTypeOptions= () => {
		apiService.getDropdownOptions("studyTypes", journalId, manuscriptId).then(res=> {
			if(res.result){
				setStudyTypesOptions(res?.result)
			}
		})
	}
	const handleShowNote = () => {
		setShowNote(false)
	}

	const handleShowGenericNote = () => {
		setShowGenericNote(false)
	}

	useEffect(() => {
		if (templateType === "Generic") {
			setShowGenericNote(true)
		}
	}, [])

	useEffect(()=>{
		getManuscriptTypes()
		getData();
		getStudyTypeOptions()
		getRSCPolicies()
		getHelpHext()	
	},[])

	useEffect(() => {
		if (studyTypesOptions.length) {
			let studyTypeCheck = false
			studyTypesOptions.map((item) => {
				if (studyType === item.name) {
					studyTypeCheck = true
				}
			})
			if(!studyTypeCheck) {
				setStudyType("")
			}
		}
	}, [studyTypesOptions])

	return (
			<div className={styles.stepOneStyle}>
				<h5>Article type selection </h5>
				<p className="mb-0">Start the formatting process by selecting the type of manuscript and study type. This will then be validated against the Instructions to Authors for your chosen journal.</p>
				<Form>
					<section className="my-2 mt-4 row">
						<Col md={6}>
							<section className="pb-3">
								<label className="d-flex justify-content-between">
									<span>Article Type <span className="text-danger">*</span></span>
									{/* {templateType !== "Generic" && <button className={styles.noteButton} 
										onClick={(e)=> {
											e.preventDefault()
											setShowNote(true)
										}}
									>
										View Note
									</button>} */}
								</label>
								<select 
									className="form-control" 
									value={manuscriptType} 
									onChange={handleSelect}
									disabled={templateType==="Generic"}
								>
									<option value="">Select article type</option>
									{manuscriptTypeOptions && manuscriptTypeOptions.map(op=> 
									<option key={op.id} value={op.name} >{op.name}</option>
									)
									}
								</select>
							</section>
							<section className="pb-3">
								<label className="">Manuscript Language <span className="text-danger">*</span></label>
								{languageOptions?.length === 1?
								<FormControl
								value={languageOptions[0]? languageOptions?.[0]?.name: ""}
								name="language"
								readOnly
								/>
								: <select 
									className="form-control"
									name="language" 
									defaultValue={languageOptions?.[0]?.code}
									value={language} 
									onChange={(e)=>setLanguage(e.target.value)}
								>
									{languageOptions && languageOptions.map((value, index)=> 
									<option key={index} value={value.code}>{value.name}</option>
									)
									}
								</select>}
							</section>
						</Col>
						<Col md={6}>
							<section className="pb-3">
								<label className="">Study Type <span className="text-danger">*</span></label>
								<select 
									className="form-control" 
									value={studyType} 
									onChange={handleStudyType}
								>
									<option value="">Select study type</option>
									{studyTypesOptions && studyTypesOptions.map((value, index)=> 
									<option key={value.id} value={value.name}>{value.name}</option>
									)
									}
								</select>
							</section>
							<section className="">
								<label className="d-flex justify-content-between align-items-center">
									<div>
										Reference Style
										<TooltipButton key="Reference style" placement="top" tooltipMessage="This journal uses the following reference style. Your references will be converted to this format. To use our reference checking tool please ensure that reference citations are all in the same format (e.g. either all in square brackets or all in superscript, NOT curly brackets).">
											<FontAwesomeIcon icon={faInfoCircle} className={classNames(styles.labelButtonLink,"align-middle pl-1")}/>
										</TooltipButton>
									</div>
									<span className={classNames("textLink cursor-pointer mb-0 mt-1 float-right")}>
										<a target="_blank" href="https://journals.ieeeauthorcenter.ieee.org/wp-content/uploads/sites/7/IEEE_Reference_Guide.pdf">View reference format details</a>
									</span>
									{/* <br />
									Your references will be converted to this format.
									<br/>
									To use our reference checking tool please ensure that reference citations are all in the same format (e.g. either all in square brackets or all in superscript, NOT curly brackets). */}
								</label>
								{ helpText.length === 1 ?
									<FormControl
										type="text"
										aria-label="referenceType"
										name="referenceType"
										value={ helpText[0]? helpText[0].name: ""}
										className={classNames(styles.customInput)}
										placeholder="Enter file description"
										readOnly
									/>
								:
									<select  
										value={referenceType} 
										onChange={(e)=> setReferenceType(e.target.value)} 
										name="referenceType"
										className="form-control"
									>
										<option value="">Select reference format</option>
										{helpText.map(help => <option key={help.id}>{help.name}</option>)}
									</select>
								}
							</section>
						</Col>
						<Col md={12} className="mb-3">
							<label>
								Manuscript Title <span className="text-danger">*</span>
								<TooltipButton tooltipMessage="Please copy and paste in your Manuscript title. This is a mandatory field and you won't be able to change this later." placement="top">
									<FontAwesomeIcon icon={faInfoCircle} className={classNames(styles.labelButtonLink, "mx-2 align-middle")} />
								</TooltipButton>
							</label> <br/>
							<span className="small text-secondary">Please ensure the title you enter here is correct and matches the title that will be used in your manuscript in Step Two. <b>When Step One is complete this title cannot be changed.</b></span>
							{stepStatus > 1 && titleLength > 0?
								<>
									<p className="mt-3 border p-2 rounded">{articleTitle}</p>
								</>
							:
								<FormControl
									className="mt-3"
									as="textarea"
									value={articleTitle}
									rows={3}
									placeholder="Enter/ paste your manuscript title here."
									onChange={(e)=> {setArticleTitle(e.target.value)}}
									area-label="Manuscript Title"
									name="articleTitle"
								/>
							}
						</Col>
						<Col md={12} className="mb-3">
							<label className="d-flex justify-content-between align-items-center mt-1">
								Validate my references
							</label>
							<div className="d-flex flex-column justify-content-between rounded border p-2">
								<div className="d-flex mb-2">
									<input className="mr-2" type="radio" name="referenceCheck" value="yes" onChange={(e) => setReferenceCheck(e.target.value)} checked={referenceCheck === "yes"}/>
									I would like my references checked and confirm they are in superscript, not square brackets/round brackets/parentheses
								</div>
								<div className="d-flex">
									<input className="mr-2" type="radio" name="referenceCheck" value="no" onChange={(e) => setReferenceCheck(e.target.value)} checked={referenceCheck === "no"}/>
									Don’t check my references
								</div>
							</div>
						</Col>
						<Col md={12} className="mb-3">
							<label className="d-flex align-items-center mt-1">
								Conduct anonymized peer review for manuscript. 
							</label>
							<div className="d-flex flex-column justify-content-between rounded border p-2">
								<div className="d-flex mb-2">
									<input className="mr-2" type="radio" name="anonymisedPeerReviewCheck" value={false} onChange={(e) => setAnonymisedPeerReviewCheck(e.target.value === "true")} checked={anonymisedPeerReviewCheck === false}/>
									My manuscript does not require anonymized peer review
								</div>
								<div className="d-flex">
									<input className="mr-2" type="radio" name="anonymisedPeerReviewCheck" value={true} onChange={(e) => setAnonymisedPeerReviewCheck(e.target.value === "true")} checked={anonymisedPeerReviewCheck === true}/>
									I request anonymized peer review for my manuscript
								</div>
								{anonymisedPeerReviewCheck ? 
									<>
										<span className="mt-2">
											Please refer to the
											<TooltipButton tooltipMessage="Download RSC policy/guidelines"> 
												<a target="_blank" href="https://www.rsc.org/globalassets/05-journals-books-databases/journal-authors-reviewers/our-processes-policies/double-anonymised-author-checklist2.docx">
													<i> Double anonymised author checklist</i>
													<FontAwesomeIcon className="ml-2" icon={faCloudArrowDown}/>
												</a>
											</TooltipButton>
										</span>
									</> 
								: 
									null
								}
							</div>
						</Col>
						<Col md={12} className="mb-3">
							<label className="d-flex justify-content-between align-items-center mt-1">
								Validate compliance by reviewing and confirming our policies
							</label>
							{rscPolicies.length > 0 ?
								<>
									<div className="rounded border p-2">
										<Row>
											{rscPolicies.map((item) => {
												return(
													<>
														<Col md={6} sm={12}>
															<p className="p-0">
																<a target="_blank" href={item.url}>
																	<i dangerouslySetInnerHTML={{__html: item.name}}></i>
																	<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
																</a>
															</p>
														</Col>
													</>
												)
											})}
										</Row>
										<div className="d-flex flex-column justify-content-between mt-2">
											<div className="d-flex mb-2">
												<input className="mr-2" type="checkbox" name="journalPolicyCheck" value={readPolicyCheck} onChange={(e) => setReadPolicyCheck(e.target.checked ? true : false)} checked={readPolicyCheck === true}/>
												I confirm that I have read the policies and comply with the journal requirements.
											</div>
										</div>
									</div>
								</>
							:	
								<p>No policies found</p>
							}
						</Col>
							

					</section>
					
				</Form> 
				
				<Modal show={false} onHide={()=> handleShowNote()} dialogClassName={classNames("", styles.styleModal)}>
					<Modal.Header  className={classNames("h5 bg-light pb-2")}>
						<span>Note</span><buton onClick={handleShowNote} className="cursor-pointer"><FontAwesomeIcon icon={faXmark} /> </buton>
					</Modal.Header>
					<Modal.Body className="py-0">
					<p className="text-secondary">The formatting tool will apply the template for <strong>{originalArticle}</strong> only and does not format other article types. Please use the template as the basis of your formatting only, and refer to the publisher’s Instructions to Authors for any other formatting that needs to be applied to the document after using this tool.</p>
					</Modal.Body>
				</Modal>

				<Modal show={showGenericNote} onHide={()=> handleShowGenericNote()} dialogClassName={classNames("", styles.styleModal)}>
					<Modal.Header  className={classNames("h5 bg-light pb-2")}>
						<span>Note</span><buton onClick={handleShowGenericNote} className="cursor-pointer"><FontAwesomeIcon icon={faXmark} /> </buton>
					</Modal.Header>
					<Modal.Body className="py-0">
						<p className="text-secondary"><strong>Please note:</strong> our generic template formatting tool will not apply specific journal style. Add your manuscript and AuthorEase will check your figure, table and reference citations. You will be able to upload your figures to be checked, create keywords, add author details and export your document and title page. You can use this document as a basis to add the specific styles of the journal you wish to submit to.</p>
					</Modal.Body>
				</Modal>

				<Modal show={show} onHide={()=> setShow(false)} size="xl" dialogClassName="text-justify">
					<Modal.Header closeButton>
						<Modal.Title>
							<span>Reference format details</span> <br/>
							<span className={classNames(styles.modalHeaderSubtext, "small text-secondary")}>Please note, some journals may use a variation of this formatting. Please check requirements for your chosen journal.</span>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={classNames(styles.modalBody, "py-0")} scrollable>
					{helpText.length && <div className=""><GuidelineTab data={helpText}  /></div>}
					</Modal.Body>
				</Modal>
			</div>
	);
}

export default SteOneForm;