import React, { useEffect, useState } from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const HtmlInput = ({rules,handleArrayChange,intialValue}) => {
  const [scopeValue, setValue] = useState(intialValue);
  useEffect(()=>{
    handleArrayChange(rules.attribute,scopeValue)
  },[scopeValue])

  return (
    <div className='w-100'><ReactQuill theme="snow" value={scopeValue} onChange={setValue} placeholder={rules.name} /></div>
  )
}

export default HtmlInput
