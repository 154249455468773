import React, { useCallback, useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import AxiosService from '../../../../../utils/AxiosService';
import RemirrorEditor, { editorRef } from '../../../../../components/RemirrorEditor/RemirrorEditor'
import { CircleLoader, FieldLoader, FullLoader } from '../../../../../components/Loader/CommonLoader';
import { Dropdown, Modal, Spinner } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Card from 'react-bootstrap/Card';
import { faArrowDown, faCaretDown, faCloudArrowUp, faSort, faUpload, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './RemirrorEditorStep.scss'
import EditingSuggestion from '../../../../../components/TooltipButton/EditingSuggestion';
import RemirrorTextEditor from './remirror';
import fileDownload from 'js-file-download';
import { useDropzone } from 'react-dropzone';

export function CustomToggle({ children, eventKey }) {
	const decoratedOnClick = useAccordionToggle(eventKey);

	return (
		<div
			onClick={decoratedOnClick}
		>
			{children}
		</div>
	);
}

const RemirrorEditorStep = ({ 
	manuscriptId, resetError, isDisabled, setIsDisabled,
	journalId, loading, setLoading, editorData, manuscriptStatus,
	setEditorData, fetchData, showModal, hideModal,
	setApiCount, showToolbar, setShowToolbar, loader,
	setUpdating, saveManuscript, saveAndContinue,
	templateType, editorChanged, setEditorChanged
}) => {

	const apiService = new AxiosService();
	const [articleType, setArticleType] = useState("")
	const [showPopulate, setShowPopulate] = useState(false)

	const [file, setFile] = useState();
	const inputFile = useRef(null);
	const [language, setLanguage] = useState("")
	const [fileNameVisible, setFileNameVisible] = useState(false)
	const [instructionModal, setInstructionModal] = useState(false)
	const [downloadErrorFileModal, setDownloadErrorFileModal] = useState(false)
	const [showDetailInstructions, setShowDetailInstructions] = useState(false)
	const [preCheckErrorModal, setPreCheckErrorModal] = useState({visible: false, errors: []})
	const [selectedFile, setSelectedFile] = useState()
	const [populateTemplateType, setPopulateTemplateType] = useState("custome")

	// const mergeLists = (docString) => {
	// 	let refrenceSection = docString.split("<p>References</p>")[1].split(/<p>[\s|\S]Table/)[0]
	// 	let refrenceList = refrenceSection.split(/<p><\/p>/).join("").split(/<ol>/).join("").split(/<\/ol>/).join("")
	// 	let refrence = "<ol>"+refrenceList+"</ol>"
	// 	let docArr = docString.split("<p>References</p>")
	// 	let refArr = docArr[1].split(/<p>[\s|\S]Table/)
	// 	refArr[0] = refrence
	// 	let refStr = refArr.join("<p>Table ")
	// 	docArr[1] = refStr
	// 	let docStr = docArr.join("<p>References</p>")
	// 	return docStr
	// }

	const uploadDocument = (file, preCheck) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("preCheck", preCheck)
		formData.append("journalId", journalId)
		formData.append("articleType", articleType)
		setLoading({ ...loading, isFileLoading: true });
		setIsDisabled({ ...isDisabled, isSaveAndValidate: true })
		resetError()
		setApiCount(1)
		setUpdating(true)
		apiService.uploadManuscriptDocument(manuscriptId, formData).then(res => {
			setUpdating(false)
			editorRef.current.setContent(res)
			setLoading({ ...loading, isFileLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			toast.success("Document uploaded successfully.", {position: "top-center", autoClose: 3000});

		}).catch(err => {
			setUpdating(false)
			setLoading({ ...loading, isFileLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			if(err.response.status === 500) {
				console.log("Status: ", err.response)
				if(err?.response?.data?.message) {
					toast.error(err.response.data.message, {autoClose: 3000})
				} else {
					toast.error(err.response.statusText, {autoClose: 3000})
				}
			} else {
				if(preCheck === "yes") {
					setPreCheckErrorModal({...preCheckErrorModal, errors: err.response.data.message, visible: true})
				}
				if (err.response) {
					toast.error(err.response.data.message, {autoClose: 3000})
				}
			}
			editorRef.current.setContent({content: [],type: "doc"})

		})
	}
	const onDrop = useCallback(
		(acceptedFiles) => {
			let obj = {
				target: {
					files: acceptedFiles,
					name: "fileUpload",
					value: ""
				},
				preventDefault: () => (null)
			}	
			handleInputChange(obj)
		},
		[articleType]
	);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop, noClick: true})

	const handleInputChange = (e) => {
		if(manuscriptStatus === "Formatted") {
			toast.warning("You're only allowed to edit the formatted manuscript. Importing new file is not allowed.", {autoClose: 3000})
			return
		}
		e.preventDefault()
		let file = e.target?.files[0]
		setFile(e.target?.files[0]);
		const fileFormat = file.name.split(".").pop()
		const editorContent = editorRef?.current?.helpers?.getJSON()
		if (file?.size >0  && (fileFormat === "docx" || fileFormat === "doc") && file!==undefined && editorContent !=undefined) {
			uploadDocument(file, "yes")
			setSelectedFile(file)
		} else {
			if(file?.size ===0 ) toast.error("Empty file found!", {autoClose: 3000})
			else if (editorContent === undefined) editorRef.current.setContent({content: [],type: "doc"})
			else toast.error("Oops! Our platform only supports docx. at the moment. We will be back soon with the PDF uploads.", {autoClose: 3000})
		}
		setFileNameVisible(true)
		e.target.value=null
	}

	const populateTemplate = (e, templateType) => {
		e.preventDefault()
		setLoading({ ...loading, isPopulateTemplateLoading: true });
		setIsDisabled({ ...isDisabled, isSaveAndValidate: true })
		// setEditorData("")
		setShowPopulate(false)
		inputFile.current.value = ""
		setFile(inputFile.current.files[0])
		setApiCount(1)
		resetError()
		setUpdating(true)
		apiService.populateTemplate(journalId, articleType, manuscriptId, templateType).then(response => {
			setUpdating(false)
			setLoading({ ...loading, isPopulateTemplateLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			resetError()
			editorRef.current.setContent(response?.result)
			toast.success("Template populated successfully.", {position: "top-center", autoClose: 3000})
		}).catch(error => {
			setUpdating(false)
			setLoading({ ...loading, isPopulateTemplateLoading: false });
			setIsDisabled({ ...isDisabled, isSaveAndValidate: false })
			toast.error(error?.data?.response?.message, {autoClose: 3000})
		})

	}

	const getData = () => {
		apiService.getStepOneDetails(manuscriptId).then(
			res => {
				const data = res.result;
				setArticleType(data?.manuscriptType)
				setLanguage(data?.language)
			}).catch(e => {
				toast.error(e?.response?.data?.message, {autoClose: 3000})
			})
	}

	function handleDeleteManuscript() {
		resetError()
		setLoading({ ...loading, isDeleteManuscript: true })
		apiService.deleteStepTwoManuscript(manuscriptId).then(res => {
			toast.success(res.result, {autoClose: 3000})
			fetchData()
			setFile()
			setLoading({ ...loading, isDeleteManuscript: false })
			hideModal({ ...showModal, isDeleteManuscript: false })
		}).catch(err => {
			setLoading({ ...loading, isDeleteManuscript: false })
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}

	function getSuggestion() {
		let obj = []
		obj = window.WEBSPELLCHECKER.getInstances()
		if (obj[0]) {
			obj[0].openDialog()
		} else {
			toast.error(`Please select text area!`, {
				position: toast.POSITION.TOP_CENTER,
				autoClose: 3000
			});
		}
	}

	useEffect(() => {
		fetchData()
		getData()
	
	}, [])

	useEffect(()=> {
		if(language.length > 0) {

			window?.WEBSPELLCHECKER?.init({
				container: document?.querySelector('.ProseMirror.remirror-editor'),
				lang: language
			});
			
			window?.WEBSPELLCHECKER?.getInstances()[1]?.setLang(language)
		}

	},[language])

	const handlePopulate = (e, templateType) => {
		let showPopUp = false
		editorRef?.current?.helpers?.getJSON()?.content?.map((item) => {
			if(item.content != undefined) {
				showPopUp = true
				return
			}
		})
		if(showPopUp) {
			setPopulateTemplateType(templateType)
			setShowPopulate(true)
		} else {
			populateTemplate(e, templateType)
		}
			
	}

	function showInstructionsModal () {
		setInstructionModal(!instructionModal)
	}

	function downloadErrorFile() {
		setLoading({...loading, isDownloadError: true})
		saveAndContinue().then((res) => {
			toast.success(res.result, {autoClose: 3000})
			setEditorChanged(false)
			setDownloadErrorFileModal(true)
			apiService.getExportErrorFile(manuscriptId, 2).then((result) => {
				toast.success("Error list downloaded successfully.", {autoClose: 3000})
				fileDownload(result, manuscriptId+"_Manuscript_Errors.xlsx")
				setLoading({...loading, isDownloadError: false})
			}).catch((err) => {
				toast.error(err.response.data.error.code, {autoClose: 3000})
				setLoading({...loading, isDownloadError: false})
			})
		}).catch((err) => {
			if (err.response.data.message) {
				toast.error(err.response.data.message, {position: "top-center", autoClose: 3000})	
			} else {
				toast.error("Something went wrong!", {autoClose: 3000})
			}
			setLoading({...loading, isDownloadError: false})
		})
	}

	return (
		<div className='pb-3 stepTwoPage'>
			<div className='d-flex flex-row justify-content-between align-items-center'>
				<h5>Upload your manuscript to AuthorEase</h5>
			</div>
			<ul className="">
				<li>Copy and paste your manuscript in the box below.</li>
				<li>Upload your manuscript using the 'Import file' button or drag and drop.</li>
				<li>Build your manuscript by selecting the 'Populate template' button and adding text under the journal's mandatory headings.</li>
				<li>Ensure the title and mandatory journal headings are present, then press the ‘Check manuscript’ button to apply the journal style and check for errors.</li>
			</ul>
			{showDetailInstructions ? 
				<div>
					{/* <p className="">When you have uploaded your manuscript, ensure the title and mandatory journal headings are present, then press the ‘Check manuscript’ button to apply journal style and check for errors. </p>
					<p className="">AuthorEase will not import tracked changes. Please accept changes before uploading.</p>
					<p className="">Please note: in the final export files, the abstract will be included in the main document. Please check the guidelines for your chosen journal to ensure it is submitted in line with their instructions.</p>
					<p className="">You will then be given a list of errors/guidance to help you ensure your manuscript meets your chosen journal's requirements. Please make the changes directly into the manuscript field on the left or edit your original document and reupload.  You can download the corrections needed by clicking on ‘Download list of errors’.</p> */}
					<h6>Important Notes:</h6>
					<p>- AuthorEase will not import tracked changes. Please accept changes before uploading.</p>
					<p>- In the final export files, the abstract will be included in the main document. Check the journal guidelines for submission instructions.</p>
					<p>- After checking, you will receive a list of errors/guidance. Make changes directly in the manuscript field or edit your original document and reupload. Download the corrections list by clicking on ‘Download list of errors’.</p>
					<button className='btn-sm show-more-button m-0 p-0' onClick={() => {setShowDetailInstructions(false)}}>Hide instructions</button>
				</div>
			:
				<button className='btn-sm show-more-button m-0 p-0' onClick={() => {setShowDetailInstructions(true)}}>Read more</button>
			}
			<div className='mt-4 mb-3'>
				<div className='w-100 border rounded p-2'>
					<div className='d-flex flex-column align-items-center'>
						<div className='w-100 d-flex flex-row justify-content-between align-items-center' {...getRootProps()}>
							{isDragActive ? 
								<>
									<div className="dragAndDropOverlay w-100 text-center align-content-center">
										<FontAwesomeIcon icon={faCloudArrowUp} className="mx-2 text-white"/> 
										<span className="strong text-white">Drag file here to upload.</span>
									</div>
								</> 
							: 
								<>
									<div className='d-flex flex-column'>
										<span>
											<strong>Import a manuscript file or drag and drop here</strong> 
											<br/>(in .docx, .doc, .pdf or .LaTex format)
										</span>
										<div className='w-100 d-flex flex-row justify-content-between align-items-center mt-2'>
											{fileNameVisible && <span>{file?.name}</span>}
										</div>
									</div>
									<button
										onClick={() => inputFile.current.click()}
										className="wide-bordered-button-secondary"
										disabled={loading.isFileLoading || loading.isPopulateTemplateLoading || manuscriptStatus === "Formatted"}
									>
										Import file
									</button>	
								</>
							}
							<input {...getInputProps({type: "file", onChange: (e) => handleInputChange(e), ref: inputFile})}/>
						</div>
						<div className='w-100 d-flex flex-row justify-content-between align-items-center'>
							<h5 className='line-label text-secondary'><span className='text-secondary'>or</span></h5>
						</div>
						<div className='w-100 d-flex flex-row justify-content-between align-items-center'>
							<span><strong>Paste the manuscript or add the mandatory headings of the journal</strong></span>
							{loading.isPopulateTemplateLoading ?
								<FieldLoader className="wide-bordered-button-secondary" isLoading={loading.isPopulateTemplateLoading} />
								:
								// <button
								// 	className='wide-bordered-button-secondary'
								// 	onClick={(e) => handlePopulate(e)}
								// 	disabled={loading.isFileLoading || loading.isUpload || templateType==="Generic"}
								// >
								// 	Populate template
								// </button>
								<Dropdown>
									<Dropdown.Toggle className='wide-bordered-button-secondary' id="dropdown-basic">
										Populate template <FontAwesomeIcon icon={faCaretDown}/>
									</Dropdown.Toggle>

									<Dropdown.Menu className='shadow'>
										<Dropdown.Item onClick={(e) => handlePopulate(e, "custom")}>Custom template</Dropdown.Item>
										<Dropdown.Item onClick={(e) => handlePopulate(e, "IMRAD")}>IRMAD template</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>

							}
						</div>
					</div>
				</div>
			</div>
			{editorData ?
			<>
				<RemirrorEditor 
					data={editorData} 
					language={language} 
					getSuggestion={getSuggestion} 
					showToolbar={showToolbar} 
					setShowToolbar={setShowToolbar} 
					loader={loader} 
					loading={loading} 
					handlePopulate ={handlePopulate}
					showModal = {showModal}
					hideModal = {hideModal}
					saveManuscript = {saveManuscript}
					downloadErrorFile = {downloadErrorFile}
					editorChanged={editorChanged} 
					setEditorChanged={setEditorChanged}
					manuscriptStatus = {manuscriptStatus}
				/> 
			 </>
			 : (loading.isFileLoading || loading.isPopulateTemplateLoading) ?
				<p className='text-center'><CircleLoader /></p>
				: ''}
			{/* Clear all changes warning popup  */}
			<Modal show={showModal.isClearAll} onHide={() => hideModal({ ...showModal, isClearAll: false })} dialogClassName="">
				<Modal.Header className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>Clearing all changes will remove all edits you have made. These cannot be retrieved.</p>
					<button className="btnOutline w-100" onClick={() => hideModal({ ...showModal, isClearAll: false })}>Cancel</button>
					{loading.isClearAll ? <FieldLoader className="w-100 mt-2" isLoading={loading.isClearAll} /> : <button onClick={fetchData} className="w-100 mt-2">Clear all changes</button>}
				</Modal.Body>
			</Modal>

			{/* Delete manuscript warning popup */}
			<Modal show={showModal.isDeleteManuscript} onHide={() => hideModal({ ...showModal, isDeleteManuscript: false })} dialogClassName="">
				<Modal.Header className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>Deleting the manuscript will remove all edits you have made. These cannot be retrieved.</p>
					<button className="btnOutline w-100" onClick={() => hideModal({ ...showModal, isDeleteManuscript: false })}>Cancel</button>
					{loading.isDeleteManuscript ? <FieldLoader className="w-100 mt-2" isLoading={loading.isDeleteManuscript} /> : <button onClick={(e) => handleDeleteManuscript()} className="w-100 mt-2">Delete manuscript</button>}
				</Modal.Body>
			</Modal>

			{/* Populate template warning popup */}
			<Modal show={showPopulate} onHide={() => setShowPopulate(false)} dialogClassName="">
				<Modal.Header className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>Are you sure you want to populate the template for the existing manuscript? Clicking on 'Populate template' will permanently remove all data.</p>
					<button className="btnOutline w-100" onClick={() => setShowPopulate(false)}>Cancel</button>
					{loading.isPopulateTemplateLoading ? <FieldLoader className="w-100 btn-info btn mt-2" isLoading={loading.isPopulateTemplateLoading} /> : <button onClick={(e) => populateTemplate(e, populateTemplateType)} className="w-100 mt-2">Populate template</button>}
				</Modal.Body>
			</Modal>

			{/* Journal detailed instruction modal */}
			<Modal show={instructionModal} onHide={() => showInstructionsModal()}>
				<Modal.Header>
					<div className='d-flex flex-column'>
						<h6>Upload / Copy - Paste your manuscript</h6>
						<p className='m-0 text-secondary'>detailed instruction</p>
					</div>
					<div>
						<button className='secondary-text-button'>
							<FontAwesomeIcon icon={faXmark} onClick={() => showInstructionsModal()}/>
						</button>
					</div>
				</Modal.Header>
				<Modal.Body className='modal-body-height'>
					<p>When you have uploaded your manuscript press the 'Save and Proceed' button to apply journal style.</p>
					<p>Please DO NOT INCLUDE figures or figure captions/legends but DO INCLUDE figure citations (e.g. see Figure 1).</p>
					<p>AuthorEase will not import tracked changes. Please accept changes before uploading.</p>
					<p>Please note: in the final export files, the abstract will be included in the main document. Please check the guidelines for your chosen journal to ensure it is submitted in line with their instructions.</p>
					<p>You will then be given a list of errors/guidance to help you ensure your manuscript meets your chosen journal's requirements. Please make the changes directly into the manuscript field on the left or edit your original document and reupload. You can download the corrections needed by clicking on ‘Download list of errors’.</p>
				</Modal.Body>
			</Modal>

			{/* Download error file notice modal */}
			<Modal show={downloadErrorFileModal} onHide={() => {setDownloadErrorFileModal(false)}} size='lg'>
				<Modal.Header className='h6' closeButton>
					Steps after downloading error file
				</Modal.Header>
				<Modal.Body>
					<p>Use your downloaded error list to work on your paper onscreen in AuthorEase or to make changes to your document offline. If you choose to use the downloaded error list to work on your paper outside AuthorEase, follow these steps to reupload your manuscript:</p>
					<ul className='list-Style-Disc'>
						<li>Go to Dashboard/Drafts</li>
						<li>Select the paper you were working on</li>
						<li>Ensure the new document has the same paper title as the original file</li>
						<li>Click on ‘Import file’ (note this will overwrite the file you originally imported and any changes you have made to it)</li>
					</ul>
					<div className='d-flex flex-row-reverse'>
						<button className='mt-3 ml-auto' disabled={loading.isDownloadError} onClick={() => {setDownloadErrorFileModal(false)}}>
							{loading.isDownloadError ? 
								<>
									<Spinner animation='grow' size='sm' className='mx-1'/>
									<Spinner animation='grow' size='sm' className='mx-1'/>
									<Spinner animation='grow' size='sm' className='mx-1'/>
								</>
							:
								"Continue"
							}
						</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* Modal for precheck errors */}
			<Modal show={preCheckErrorModal.visible} onHide={() => {setPreCheckErrorModal({...preCheckErrorModal, visible: false})}} size='lg'>
				<Modal.Header className='h6' closeButton>
					File pre check errors!
				</Modal.Header>
				<Modal.Body>
					<p>Following errors are found while uploading your file. Are you sure you want to continue?</p>
					<ol>
						{preCheckErrorModal.errors.map((item) => {
							return (
								<li>{item}</li>
							)
						})}
					</ol>
				</Modal.Body>
				<Modal.Footer>
					<button className='w-25 btnOutline' onClick={() => {setPreCheckErrorModal({...preCheckErrorModal, visible: false})}}>
						Close
					</button>
					<button className='w-25' onClick={() => {
						uploadDocument(selectedFile, "no")
						setPreCheckErrorModal({...preCheckErrorModal, visible: false, errors: [], file: {}})
					}}>
						{loading.isDownloadError ? 
							<>
								<Spinner animation='grow' size='sm' className='mx-1'/>
								<Spinner animation='grow' size='sm' className='mx-1'/>
								<Spinner animation='grow' size='sm' className='mx-1'/>
							</>
						:
							"Continue"
						}
					</button>
				</Modal.Footer>
			</Modal>
			{loader ? <FullLoader loaderMessage={[
				"Loading manuscript... Crafting it to perfection for the next stage of publication.", 
				"Setting the manuscript... We're fine-tuning it for optimal publication.", 
				"Building the detailed checklist... Organizing the checks.", 
				"Polishing the manuscript... Ensuring every detail shines."
			]}/> : null}

		</div>

		// <RemirrorTextEditor />
	)
}

export default RemirrorEditorStep
