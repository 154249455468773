import React, { useRef, useState } from "react"
import { Accordion, Button, Card, Col, Modal, Row, Spinner, Tab, Tabs } from "react-bootstrap"
import StepThreeForm from "../../SubmissionProcessPages/StepThreeForm/StepThreeForm";
import StepTwoForm from "../../SubmissionProcessPages/StepTwoForm/StepTwoForm";
import "./MultiStepTabView.scss"
import StepOneForm from "../../SubmissionProcessPages/StepOneForm/StepOneForm";
import StepFourForm from "../../SubmissionProcessPages/StepFourForm/StepFourForm";
import StepFiveForm from "../../SubmissionProcessPages/StepFiveForm/StepFiveForm";
import StepSixForm from "../../SubmissionProcessPages/StepSixForm/StepSixForm";
import AxiosService from "../../../../../utils/AxiosService";
import { toast } from "react-toastify";
import { CircleLoader, FieldLoader } from "../../../../../components/Loader/CommonLoader";
import { useHistory, useLocation, Prompt } from "react-router-dom";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp, faCheck, faChevronDown, faChevronUp, faExclamationTriangle, faExternalLink, faSliders } from "@fortawesome/free-solid-svg-icons";
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import ActionView from "../ActionView/ActionView";
import { editorRef } from "../../../../../components/RemirrorEditor/RemirrorEditor";
import RemirrorEditorStep from "../../SubmissionProcessPages/RemirrorEditor/RemirrorEditorStep";
import ErrorItem from "../../SubmissionProcessPages/ValidationErrorTable/ErrorItem";
import { useDispatch } from "react-redux";
import { setGateOpen } from "../../../../../store/actions/NavigateAwayAlertAction";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import { hasCitationScoreSort } from "../../../../../store/actions/SortConfigAction";



const MultiStepTabView = ({ manuscriptId, stepStatus, journal, handleStepStatus, handleGuidelines, setExitFunction, setStepOneInfo }) => {
	const search = useLocation().search;
	const history = useHistory()
	const type = new URLSearchParams(search).get("type") || "";
	const tabIndex = Number(new URLSearchParams(search).get("step")) || stepStatus;
	const step = type === "edit" ? tabIndex : stepStatus

	const [selectedTab, setSelectedTab] = useState(step);
	const [errorMessage, setErrorMessage] = useState();
	const [loading, setLoading] = useState({isFileLoading: false, isPopulateTemplateLoading: false, isUpload: false, isDeleteManuscript: false, isClearAll: false, isDownloadError: false})
    const [showModal, hideModal] = useState({isClearAll: false, isDeleteManuscript: false})
   	const [apiCount, setApiCount] = useState(1)
    const [visibleSkipErrorBtn, setVisibleSkipErrorBtn] = useState(false)
    const [skipStepTwoError, setSkipStepTwoError] = useState({isSkipError: false, isContinueValidate: false, isPrevError: false, isPrevVisible: false})

	const [isSaving, setSaving] = useState(false);
	const apiService = new AxiosService();
	const [loader, setLoader] = useState({ finishBtnLoader: false, nextBtnLoader: false, prevBtnLoader: false, skipBtnLoader: false, saveBtnLoader: false});
	const [isSkiped, setIsSkiped] = useState(false);
	const totalSteps = 6;
	const [referenceType, setReferenceType] = useState("");
	const [manuscriptType, setManuscriptType] = useState("");
	const [mandatoryFieldSet, setMandatoryFieldSet] = useState({ abstract: {}, authors: {}, keywords: {}, title: {} })
	const [studyType, setStudyType] = useState("")
	const [language, setLanguage] = useState("")
	const [referenceCheck, setReferenceCheck] = useState("yes")
	const [articleTitle, setArticleTitle] = useState("")
	const [anonymisedPeerReviewCheck, setAnonymisedPeerReviewCheck] = useState(false)
	const [readValidationChecklist, setReadValidationChecklist] = useState(false)
	const [checklistStatus, setChecklistStatus] = useState(false)
	const [readPolicyCheck, setReadPolicyCheck] = useState(false)
	const [readPolicyModal, setReadPolicyModal] = useState({visible: false, loading: false})
	// text editor toolbar hide/show
	const [showToolbar, setShowToolbar] = useState(true)

	const [isDisabled, setIsDisabled] = useState({isSaveAndValidate: false})
	const [skipConfirmationModal, setSkipConfirmationModal] = useState({status: false, index: -1})
	const [stepFourSkipable, setStepFourSkipable] = useState(true)
	const [type2Error, setType2Error] = useState()
	const [type3Error, setType3Error] = useState()
	const [type4Error, setType4Error] = useState()
	const [type5Error, setType5Error] = useState()
	const [type6Error, setType6Error] = useState()
	const [typeAutoCorrect, setTypeAutoCorrect] = useState()
	const [typeAuthor, setTypeAuthor] = useState()
	const [typeIndexTerms, setTypeIndexTerms] = useState()

	const [shouldAllowNavigation, setShouldAllowkNavigation] = useState(false)
	const [blockedRoute, setBlockedRoute] = useState(false)
	const [blockNavigationModal, setBlockNavigationModal] = useState(false)
	const [navigationGate, setNavigationGate] = useState(true)
	const [isDataUpdating, setIsDataUpdating] = useState(false)
	const [errorList, setErrorList] = useState([])
	const [listError, setListError] = useState([])
	const [stepCompleteModal, setStepCompleteModal] = useState(false)
	const [editorChanged, setEditorChanged] = useState(false)
	const [localNavigationWarningModal, setLocalNavigationWarningModal] = useState({visible: false, step: 0})
	const [journalConfigChecklist, setJournalConfigChecklist] = useState({})
	const [journalConfigModal, setJournaLConfigModal] = useState({visible: false, loading: false, closable: false})
	const [journalConfigAccordianKey, setJournalAccordianKey] = useState("0")
	const [defaultAuthorTab, setDefaultAuthorTab] = useState("author")
	const abstractLimit = new RegExp(/<([a-zA-Z]+)(\s+[\w-]+="[^"]*")*\s*>abstract<\/\1>/gi);

	const dispatch = useDispatch()
	const tabRef = useRef()

	const [key, setKey] = useState(0)

	const data = [
		{ id: 1, title: "Manuscript type, study type, reference format", step: "STEP #1" },
		{ id: 2, title: "Upload and edit your paper", step: "STEP #2" },
		{ id: 3, title: "Upload supplementary files", step: "STEP #3" },
		{ id: 4, title: "Select keywords", step: "STEP #4" },
		{ id: 5, title: "Your details", step: "STEP #5" },
		{ id: 6, title: "Manuscript preview", step: "STEP #6" },
	];

	const sideScroll = (element, speed, distance, step) => {
		let tabContainer = element.children[0]
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            tabContainer.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
        }, speed);
    };

	const handleSelect = (tabIndex, queryParam) => {
		if (tabIndex <= stepStatus && !isSaving && !loader.nextBtnLoader) {
			if(editorChanged && selectedTab === 2) {
				setLocalNavigationWarningModal({visible: true, step: tabIndex})
			} else {
				resetError()
				setErrorList([])
				setSelectedTab(tabIndex)
				if (queryParam) {
					// history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex}&${queryParam}`)
					setDefaultAuthorTab("reviewer")
				} else {
					setDefaultAuthorTab("author")
					// history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex}`)
				}
				history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex}`)

			}
		}
		if (tabIndex > selectedTab) {
			sideScroll(tabRef.current, 10, 50, 10)
		} else {
			sideScroll(tabRef.current, 10, 50, -10)
		}
	};


	const handleSaveStep = (tabIndex) => {
		setLoader({ ...loader, nextBtnLoader: true });
		resetError()
		setListError([])
		setKey(0)
		if (tabIndex === 1) {
			if(readPolicyCheck === "no") {
				setReadPolicyModal({...readPolicyModal, visible: true})
				setLoader({ ...loader, nextBtnLoader: false });
				return
			}
			const data = {
				"manuscriptType": manuscriptType,
				"studyType": studyType,
				"referenceFormat": referenceType,
				"language": language,
				"referenceCheck": referenceCheck,
				"manuscriptTitle": articleTitle?.length ? articleTitle.trim() : articleTitle,
				"readPoliciesAndComply": readPolicyCheck,
				"anonymizedPeerReview": anonymisedPeerReviewCheck,
				"readingChecklist": readValidationChecklist
			}
			apiService.updateStepOneDetails(manuscriptId, data).then(
				res => {
					setIsSkiped(true);
					handleNextStep(tabIndex)
					toast.success(res.result, {
						position: "top-center",
						autoClose: 3000
					})
				}).catch(e => {
					setTimeout(() => {setLoader({ ...loader, nextBtnLoader: false });}, 2000)
					toast.error(e.response.data.message,{
						position: "top-center",
						autoClose: 3000
					})
				});
		} else if(tabIndex === 2) {
			const data = { 
				"data": editorRef?.current?.helpers?.getJSON(),
				"htmlContent": ""
			}
			let htmlContent =  editorRef?.current?.helpers?.getHTML().toString().split(abstractLimit)
			data.htmlContent = htmlContent[0]
			let emptyLineFound = false
			let emptyLineCount = 0
			data.data.content.map((item) => {
				if(item.type === "paragraph" && item.content === undefined && !emptyLineFound) {
					emptyLineCount++
				} else {
					emptyLineFound = true
				}
			})
			data.data.content.splice(0, emptyLineCount)
			if(data?.data?.content) {
				if(data?.data?.content?.length > 0) {
					apiService.saveData(manuscriptId, data).then (res => {
						toast.success(res.result, {
							position: "top-center",
							autoClose: 3000
						})
						setEditorChanged(false)
						handleNextStep(tabIndex)
					}).catch (err => {
						setTimeout(() => {setLoader({ ...loader, nextBtnLoader: false })}, 2000)
						toast.error(err.response.data.message,{
							position: "top-center",
							autoClose: 3000
						})
					})
				} else {
					setLoader({ ...loader, nextBtnLoader: false })
					toast.error("There is no manuscript to check. Please upload/paste in a manuscript.", {position: "top-center", autoClose: 3000})
					editorRef.current.setContent([])
				}
			} else {
				setLoader({ ...loader, nextBtnLoader: false })
				toast.error("Content not found!", {position: "top-center", autoClose: 3000})
				editorRef.current.setContent([])
			}
			
		}
		else {
			handleNextStep(tabIndex)
		}
	}

	function skipAndContinue() {
		toast.success("Skipped successfully!", {
			position: "top-center",
			autoClose: 3000
		})
		setErrorMessage()
		setLoader({ ...loader, nextBtnLoader: false })
		history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex + 1}`)										
	}

	const handleNextStep = async (tabIndex, errorType="skipError") => {
		if (tabIndex === 2) {
			let listError= []
			setLoader({ ...loader, nextBtnLoader: true });
			setIsDataUpdating(true)
			const typeAutoCorrect = await apiService.validateData(manuscriptId, "AutoCorrect").then(res => {
				setTypeAutoCorrect()
				// fetchData()
				return res
			}).catch(err => {
				setTypeAutoCorrect(err.response.data.message)
				// fetchData()
				return err.response
			})

			const typeAuthor = await apiService.validateData(manuscriptId, "Authors").then(res => {
				setTypeAuthor()
				// fetchData()
				return res
			}).catch(err => {
				setTypeAuthor(err.response.data.message)
				// fetchData()
				return err.response
			})

			const type1 = await apiService.validateData(manuscriptId, "Title_Abstract").then(res => {
				res.details = []
				if(res.passList) {
					setErrorMessage(res)
				} else {
					setErrorMessage()
				}
				return res
			}).catch(err => {
				setErrorMessage(err.response.data.message)
				return err.response
			})

			const typeIndexTerm = await apiService.validateData(manuscriptId, "indexTerms").then(res => {
				res.details = []
				if(res.passList) {
					setTypeIndexTerms(res)
				} else {
					setTypeIndexTerms()
				}
				fetchData()
				return res
			}).catch(err => {
				setTypeIndexTerms(err.response.data.message)
				if(err?.response?.status === 500) {
					setLoader({ ...loader, nextBtnLoader: false })
					setIsDataUpdating(true)
				}
				return err.response
			})

			const type2 = await apiService.validateData(manuscriptId, "Table").then(res => {
				res.details = []
				if(res.passList) {
					setType2Error(res)
				} else {
					setType2Error()
				}
				return res
			}).catch(err => {
				setType2Error(err.response.data.message)
				return err.response
			})

			const type4 = await apiService.validateData(manuscriptId, "Figure").then(res => {
				res.details = []
				if(res.passList) {
					setType4Error(res)
				} else {
					setType4Error()
				}
				return res
			}).catch(err => {
				setType4Error(err.response.data.message)
				return err.response
			})

			const type5 = await apiService.validateData(manuscriptId, "Reference").then(res => {
				res.details = []
				if(res.passList) {
					setType5Error(res)
				} else {
					setType5Error()
				}
				return res
			}).catch(err => {
				setType5Error(err.response.data.message)
				return err.response
			})

			const type6 = await apiService.validateData(manuscriptId, "Other").then(res => {
				res.details = []
				if(res.passList) {
					setType6Error(res)
				} else {
					setType6Error()
				}
				fetchData()
				return res
			}).catch(err => {
				setType6Error(err.response.data.message)
				fetchData()
				if(err?.response?.status === 500) {
					setLoader({ ...loader, nextBtnLoader: false })
					setIsDataUpdating(true)
				}
				return err.response
			})
				
			if (typeAutoCorrect?.data && type1?.data && type2?.data && type4?.data && type5?.data && type6?.data && typeAuthor?.data && typeIndexTerm?.data ) {
				setLoader({ ...loader, nextBtnLoader: false })
				setIsDataUpdating(true)
			} else if (typeAutoCorrect?.message && type1?.message && type2?.message && type4?.message && type5?.message &&type6?.message && typeAuthor?.message && typeIndexTerm?.message) {
				setLoader({ ...loader, nextBtnLoader: false })
				setErrorList([listError])
				handleValidateData(tabIndex, false);
				setIsDataUpdating(true)
			} else {
				setLoader({ ...loader, nextBtnLoader: false })
				setErrorList([listError])
				setIsDataUpdating(true)
			}
		}
		else if (tabIndex === 3) {
			setLoader({ ...loader, nextBtnLoader: true });
			getValidationData("File")
		}
		else if (tabIndex > 3) {
			setIsSkiped(true);
			setLoader({ ...loader, nextBtnLoader: true });
			handleValidateData(tabIndex - 1, false);
		} else {
			setIsSkiped(true);
			setLoader({ ...loader, nextBtnLoader: true });
			handleValidateData(tabIndex, false);
		}

	}

	const getValidationData = (type) => {
		apiService.validateData(manuscriptId, type)
			.then(res => {
				setLoader({ ...loader, nextBtnLoader: false })
				setSkipStepTwoError({...skipStepTwoError, isContinueValidate: false, isSkipError: false, isPrevError:false})
				resetError()
				handleStepStatus();
				// setSelectedTab(tabIndex + 1);
				// history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex + 1}`)
				setStepCompleteModal(true)
			}).catch(err => {
				setLoader({ ...loader, nextBtnLoader: false })
				if(err.response.data.message.details){
					if (type === 2) {
						setType2Error(err.response.data.message)
						setErrorList([...errorList , [{header: err.response.data.message.header, details: err.response.data.message.details}]])
					}
					else if (type === 3) {
						setType3Error(err.response.data.message)
						setErrorList([...errorList , [{header: err.response.data.message.header, details: err.response.data.message.details}]])
					}
					else if (type === 4) {
						setType4Error(err.response.data.message)
						setErrorList([...errorList , [{header: err.response.data.message.header, details: err.response.data.message.details}]])
					}
					else {
						setErrorMessage(err.response.data.message)	
						setErrorList([...errorList , [{header: err.response.data.message.header, details: err.response.data.message.details}]])
					}
				}
				else {
				toast.error(err.response.data.message, {autoClose: 3000})
				}
				setSkipStepTwoError({...skipStepTwoError, isContinueValidate: false, isSkipError: false, isPrevError:false})
			})

	}


	const resetError = () => {
		setErrorMessage();
		setType2Error()
		setType3Error()
		setType4Error()
		setType5Error()
		setType6Error()
		setTypeAutoCorrect()
		setTypeAuthor()
		setTypeIndexTerms()
	}

	const handleSkipStep = (tabIndex) => {
		let authorError = false
		if(errorMessage?.details?.length) {
			errorMessage?.details.map((item) => {
				if (item.errorKey && item.errorKey === "minimumOneCorrespondingAuthor") {
					authorError = true
				}
			})
		}
		if(!authorError) {
			if(skipConfirmationModal.status) {
				setLoader({ ...loader, skipBtnLoader: true });
				resetError()
				if (tabIndex > 3) handleValidateData(tabIndex - 1, true);
				else handleValidateData(tabIndex, true);
				setSkipConfirmationModal(false)
				setErrorList([])
				setEditorChanged(false)
			} else {
				if(tabIndex === 2 && type4Error?.details?.length> 0) {
					type4Error?.details?.filter(val => {
						if(val?.errorKey === "figureNotAllowed" && val?.figureCount > 0) {
							toast.error(val?.description, {position: "top-center", autoClose: 3000,})
						}else {
							setSkipConfirmationModal({status: true, index: tabIndex})
						}
					})
				}
				else {
					setSkipConfirmationModal({status: true, index: tabIndex})
				}
			}
		} else {
			toast.error(errorMessage?.details[0]?.description, {autoClose: 3000})
		}
	}

	const handleValidateData = (skipStep, isSkipped) => {
		apiService.validateStepsData(manuscriptId, skipStep, isSkipped).then(res => {
			setLoader({ ...loader, nextBtnLoader: false, skipBtnLoader: false })
			setSkipStepTwoError({...skipStepTwoError, isContinueValidate: false, isSkipError: false, isPrevError:false})
			// resetError()
			handleStepStatus();
			if(isSkipped || tabIndex === 1 || tabIndex === 6 || tabIndex === 4 || tabIndex === 5) {
				if(tabIndex === 2) {
					apiService.updateStepStatus(manuscriptId, {status: "Completed"}).then((res) => {
						// setStepCompleteModal(true)
					}).catch(error => {
						toast.error(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.status + " : " + error?.response?.statusText)
					})
				}
				setSelectedTab(tabIndex+1)
				history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex + 1}`)
			} else {
				if(tabIndex === 2) {
					apiService.updateStepStatus(manuscriptId, {status: "Completed"}).then((res) => {
						// setSelectedTab(tabIndex+1)
						// history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex + 1}`)
						setStepCompleteModal(true)
					}).catch(error => {
						toast.error(error?.response?.data?.message ? error?.response?.data?.message : error?.response?.status + " : " + error?.response?.statusText)
					})
				}
				setStepCompleteModal(true)
				// setSelectedTab(tabIndex+1)
				// history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex + 1}`)
			}
		}).catch(err => {
			setSkipStepTwoError({...skipStepTwoError, isContinueValidate: false, isSkipError: false, isPrevError:false})
			setLoader({ ...loader, nextBtnLoader: false, skipBtnLoader: false })
			setErrorMessage(err.response.data.message)
			setErrorList([...errorList , [{header: err.response.data.message.header, details: err.response.data.message.details}]])

			toast.error(err.response.data.message, {
				position: "top-center",
				autoClose: 3000
			})
		})
	}

	const handleMandatoryCheck = (articleType) => {
		if (articleType.trim()) {
			apiService.fecthMandatoryFieldsInfo(journal.journalId, articleType, manuscriptId).then(res => {
				setMandatoryFieldSet({ ...mandatoryFieldSet, abstract: res.result.filter(value => value.field === "abstract"), authors: res.result.filter(value => value.field === "authors"), keywords: res.result.filter(value => value.field === "keywords"), title: res.result.filter(value => value.field === "title") })
			}).catch(err => {
				setMandatoryFieldSet({ ...mandatoryFieldSet, abstract: {}, authors: {}, keywords: {}, title: {} })
				toast.error(err.response.data.message, {
					autoClose: 3000,
				})
			})
		} else {
			setMandatoryFieldSet({ ...mandatoryFieldSet, abstract: {}, authors: {}, keywords: {}, title: {} })
		}
	}

	const saveAndExit = () => {
		const data = { 
			"data": editorRef?.current?.helpers?.getJSON(),
			"htmlContent": ""
		}
		let htmlContent =  editorRef?.current?.helpers?.getHTML().toString().split(abstractLimit)
		data.htmlContent = htmlContent[0]
		let emptyLineFound = false
		let emptyLineCount = 0
		data.data.content.map((item) => {
			if(item.type === "paragraph" && item.content === undefined && !emptyLineFound) {
				emptyLineCount++
			} else {
				emptyLineFound = true
			}
		})
		data.data.content.splice(0, emptyLineCount)
		if(data?.data?.content) {
			apiService.saveData(manuscriptId, data).then(
				res => {
					dispatch(setGateOpen())
					toast.success(res.result, {
						position: "top-center",
						autoClose: 3000
					})
				}
			).catch(err => {
				setTimeout(() => {setLoader({ ...loader, nextBtnLoader: false })}, 2000)
				toast.error(err.response.data.message,{
					position: "top-center",
					autoClose: 3000
				})
				
			})
		}
	} 

	const saveAndContinue = () => {
		const data = { 
			"data": editorRef?.current?.helpers?.getJSON(),
			"htmlContent": ""
		}
		let htmlContent =  editorRef?.current?.helpers?.getHTML().toString().split(abstractLimit)
		data.htmlContent = htmlContent[0]
		let emptyLineFound = false
		let emptyLineCount = 0
		data.data.content.map((item) => {
			if(item.type === "paragraph" && item.content === undefined && !emptyLineFound) {
				emptyLineCount++
			} else {
				emptyLineFound = true
			}
		})
		data.data.content.splice(0, emptyLineCount)
		if(data?.data?.content) {
			return apiService.saveData(manuscriptId, data)
		} else {
			return Promise.reject("Content was empty.")
		}
	}
 
	useEffect(() => {
		setSelectedTab(tabIndex)
		history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex}`)
	}, [tabIndex])

	const [editorData, setEditorData] = useState({
        content: [],
        type: "doc"
    })

	const fetchData = (doRefresh) => {
		setLoading({...loading, isFileLoading: true, isClearAll: true})
		setIsDisabled({...isDisabled, isSaveAndValidate: true})
		setIsDataUpdating(true)
	
		apiService.fetchDocumentData(manuscriptId).then(res=> {
			setIsDataUpdating(false)

			setLoading({...loading, isFileLoading: false, isClearAll: false})
			setIsDisabled({...isDisabled, isSaveAndValidate: false})
			hideModal({...showModal, isClearAll: false, isDeleteManuscript: false})
			editorRef?.current?.setContent({
					content: res?.content,
					type: "doc"
				})
			setEditorData({
				content: res?.content,
				type: "doc"
			})
			if (doRefresh) {
				handleSaveStep(selectedTab)
			}
		}).catch(error => {
			setIsDataUpdating(false)

			setLoading({...loading, isFileLoading: false, isClearAll: false})
			setIsDisabled({...isDisabled, isSaveAndValidate: false})
			toast.error(error?.response?.data?.message, {autoClose: 3000})
		})
	}

	const exitFunction = (tabIndex) => {
		setIsDisabled({isSaveAndValidate: true})
		if (tabIndex === 1) {
			const data = {
				"manuscriptType": manuscriptType,
				"studyType": studyType,
				"referenceFormat": referenceType,
				"language": language,
				"referenceCheck": referenceCheck,
				"manuscriptTitle": articleTitle,
				"readPoliciesAndComply": readPolicyCheck,
				"anonymizedPeerReview": anonymisedPeerReviewCheck,
				"readingChecklist": readValidationChecklist
			}
			apiService.updateStepOneDetails(manuscriptId, data).then((res) => {
				dispatch(setGateOpen())
				toast.success(res.result, {
					position: "top-center",
					autoClose: 3000
				})
				setTimeout(() => {
					setIsDisabled({isSaveAndValidate: false})
					history.push("/my-dashboard")
				}, 500)
			},(err) => {
				toast.error(err.response.data.message,{
					position: "top-center",
					autoClose: 3000
				})
				setIsDisabled({isSaveAndValidate: false})
			})
		} else if (tabIndex === 2) {
			const data = { 
				"data": editorRef?.current?.helpers?.getJSON(),
				"htmlContent": ""
			}
			let htmlContent =  editorRef?.current?.helpers?.getHTML().toString().split(abstractLimit)
			data.htmlContent = htmlContent[0]
			let emptyLineFound = false
			let emptyLineCount = 0
			data.data.content.map((item) => {
				if(item.type === "paragraph" && item.content === undefined && !emptyLineFound) {
					emptyLineCount++
				} else {
					emptyLineFound = true
				}
			})
			data.data.content.splice(0, emptyLineCount)
			if(data?.data?.content) {
				apiService.saveData(manuscriptId, data).then((res) => {
					dispatch(setGateOpen())
					toast.success(res.result, {
						position: "top-center",
						autoClose: 3000
					})
					setTimeout(() => {
						setIsDisabled({isSaveAndValidate: false})
						history.push("/my-dashboard")
					}, 500)
				}, (err) => {
					toast.error(err.response.data.message,{
						position: "top-center",
						autoClose: 3000
					})	
					setIsDisabled({isSaveAndValidate: false})
				})
			} else {
				setTimeout(() => {
					toast.warning("No data to be saved", {position: "top-center", autoClose: 3000})
				}, 2000)
				setIsDisabled({isSaveAndValidate: false})
			}
		} else {
			dispatch(setGateOpen())
			toast.success("Manuscript updated successfully.", {position: "top-center", autoClose: 3000})
			setTimeout(() => {
				setIsDisabled({isSaveAndValidate: false})
				history.push("/my-dashboard")
			}, 500)
		}
	}

	function renderSaveAndValidateButtonText (step) {
		if(errorMessage || type2Error || type3Error || type4Error || type5Error || type6Error || typeAutoCorrect) {
		  switch (step) {
			case 2:
			  return "Save and check"
			case 3:
			  return "Save and check"
			default:
			  return "Save and check"
		  }
		} else {
		  switch (step) {
			case 1:
			  return "Save and Proceed to Step Two"
			case 2:
			  return "Check manuscript"
			case 3:
			  return "Check submission files"
			case 4:
			  return "Save and Proceed to Step Five"
			case 5:
			  return "Save and Proceed to Step Six"
			default:
			  return "Save and Proceed"
		  }
		}
	}

	const getJournalConfigList = () => {
		apiService.getAdminGuidelines(journal.journalId).then((res) => {
			setJournalConfigChecklist(res)
			if(stepStatus <= 1) {
				setJournaLConfigModal({...journalConfigModal, visible: true})
			}
		}).catch((err) => {
			toast.error(err?.response?.datd?.message)
		})
	}

	const renderJournalConfigValue = (value) => {
		if (typeof value === "string" || typeof value === "number") {
			if (value.length <= 0) {
				return false
			} else {
				return value
			}
		} else {
			if (value.length <= 0) {
				return false
			} else {
				return value.join(", ")
			}
		}
	}

	const getEmptyCount = (header) => {
		let falseValueCount = 0
		journalConfigChecklist.sections[header]?.map((item, index) => {
			if(renderJournalConfigValue(item.value) === false) {
				falseValueCount += 1
			}
		})
		return falseValueCount
	}

	const handleValidationChecklistContinue = () => {
		if(stepStatus > 1) {
			setJournaLConfigModal({...journalConfigModal, visible: false, loading: false})
		} else {
			setJournaLConfigModal({...journalConfigModal, loading: true})
			if (checklistStatus) {
				setJournaLConfigModal({...journalConfigModal, visible: false, loading: false})
			} else {
				apiService.postReadingChecklist(manuscriptId).then((res) => {
					toast.success(res?.result, {autoClose: 3000})
					setJournaLConfigModal({...journalConfigModal, visible: false, loading: false})
				}).catch(err => {
					toast.error(err?.response?.data)
					setJournaLConfigModal({...journalConfigModal, loading: false})
				})
			}
		}
	}

	const getReadingCheckList = () => {
		setJournaLConfigModal({...journalConfigModal, loading: true})
		apiService.getReadingChecklist(manuscriptId).then((res) => {
			setReadValidationChecklist(res.readingChecklist)
			setJournaLConfigModal({...journalConfigModal, loading: false, closable: res.readingChecklist})
		}).catch((err) => {
			toast.error(err?.response?.data?.message)
			setJournaLConfigModal({...journalConfigModal, loading: false, visible: false})
		})
	}

	useEffect(() => {
		setExitFunction(() => {
			if (tabIndex === 1) {
				return () => {
					const data = {
						"manuscriptType": manuscriptType,
						"studyType": studyType,
						"referenceFormat": referenceType,
						"language": language,
						"referenceCheck": referenceCheck,
						"manuscriptTitle": articleTitle,
						"readPoliciesAndComply": readPolicyCheck,
						"anonymizedPeerReview": anonymisedPeerReviewCheck,
						"readingChecklist": readValidationChecklist
					}
					return apiService.updateStepOneDetails(manuscriptId, data)
				}
			} else if (tabIndex === 2) {
				return () => {
					const data = { 
						"data": editorRef?.current?.helpers?.getJSON(),
						"htmlContent": ""
					}
					let htmlContent =  editorRef?.current?.helpers?.getHTML().toString().split(abstractLimit)
					data.htmlContent = htmlContent[0]
					let emptyLineFound = false
					let emptyLineCount = 0
					data.data.content.map((item) => {
						if(item.type === "paragraph" && item.content === undefined && !emptyLineFound) {
							emptyLineCount++
						} else {
							emptyLineFound = true
						}
					})
					data.data.content.splice(0, emptyLineCount)
					if(data?.data?.content) {
						return apiService.saveData(manuscriptId, data)
					} else {
						const promise = Promise.resolve(
							setTimeout(() => {
								toast.warning("No data to be saved", {position: "top-center", autoClose: 3000})
							},2000)
						)
						return promise
					}
				}
			} else {
				return () => {
					const promise = Promise.resolve(
						setTimeout(() => {
							toast.success("Manuscript updated successfully.", {position: "top-center", autoClose: 3000})
						}, 2000)
					)
					return promise
				}
			}
		})
	}, [tabIndex, manuscriptType, studyType, referenceType, language, referenceCheck, articleTitle, readPolicyCheck, anonymisedPeerReviewCheck, readValidationChecklist])


	useEffect(() => {
		apiService.getPaymentStatus(manuscriptId).then((res) => {
			if (res.status !== "closed") {
				dispatch(setGateOpen())
				toast.warning("Payment for this manuscript is pending. Please complete the payment!", {autoClose: 3000})
				setTimeout(() => {
					history.push("/my-dashboard?status=Draft&from=1")
				}, 500) 
			}
		}).catch((err) => {
			dispatch(setGateOpen())
			console.log("Error in getting payment status: ", err?.response?.data?.message)
			toast.error("Payment status not varified please try again!", {autoClose: 3000})
			setTimeout(() => {
				history.push("/my-dashboard?status=Draft&from=1")
			}, 500)
		})
	}, [step])

	useEffect(() => {
		getJournalConfigList()
	},[])

	return (
	<div className="bg-white rounded border mt-4 shadow-sm">
			<div className="sticky-div-1 bg-white d-flex flex-row z-index-10000 p-0 border-bottom">

				<Col md={8} ref={tabRef} className="p-0">
					<ul className="tabList pl-0 d-flex sticky-div bg-white m-0">
						{data.map((item, index) => (
							<li
								onClick={() => handleSelect(item.id)}
								className={selectedTab === item.id ? "active p-4" : "p-4"}
								key={item.id}
							>
								<TooltipButton tooltipMessage={item.title} placement="top">
									<p className={stepStatus >= item.id ? selectedTab === item.id ? "activeTabTitle font-weight-bold mb-1 d-flex justify-content-between" : "tabTitle font-weight-bold mb-1 d-flex justify-content-between" : "mb-1 diabledTab d-flex justify-content-between"}>
										{item.step}
										{item.id === 2 && editorChanged ? 
											<>
												<TooltipButton tooltipMessage="Unsaved changes found" placement="bottom">
													<span><FontAwesomeIcon icon={faFloppyDisk} className="text-danger"/></span>
												</TooltipButton>
											</> 
										: 
											""
										}
										{stepStatus >= item.id && <FontAwesomeIcon icon={faCheck} className={selectedTab === item.id ? "activeTabTitle font-weight-bold mb-1 d-flex justify-content-between" : "tabTitle font-weight-bold mb-1 d-flex justify-content-between"} />}
									</p>
								</TooltipButton>
							</li>
						))}
					</ul>
				</Col>
				<Col md={4} className="p-0">
					<div className="p-3 d-none d-sm-flex flex-column">
						<div className="d-flex flex-row justify-content-between mb-1">
							<p className="m-0 small">Journal selected for submission</p>
							<p className="link m-0 small" onClick={() => handleGuidelines()}>{journal?.templateType==="Generic"?'' :''}Submission Checklist</p>
						</div>
						<div className="d-none d-sm-flex flex-row justify-content-between">
							<div className="w-50">
								<TooltipButton tooltipMessage={journal !== "NA" ? journal?.templateType==="Generic"?'Generic Template - '+journal.journalTitle : journal.journalTitle : "NA"} placement="top">
									<p className="font-weight-bold m-0 small text-truncate">{journal !== "NA" ? journal?.templateType==="Generic"?'Generic Template - '+journal.journalTitle : journal.journalTitle : "NA"}</p>
								</TooltipButton>
							</div>
							<p className="link m-0 small" onClick={() => {setJournaLConfigModal({...journalConfigModal, visible: true})}}>Config Checklist</p>
						</div>
					</div>
				</Col>
			</div>

			<Row>

			
			<Col md={8} >
				<div>
					<section className="common-height content-section py-3 px-3">
						{selectedTab === 1 && <StepOneForm manuscriptId={manuscriptId} handelError={setErrorMessage} templateType={journal.templateType} journalId={journal.journalId} stepStatus={stepStatus} handleSave={setSaving} manuscriptType={manuscriptType} setManuscriptType={setManuscriptType} studyType={studyType} setStudyType={setStudyType} referenceType={referenceType} setReferenceType={setReferenceType} language={language} setLanguage={setLanguage} referenceCheck={referenceCheck} setReferenceCheck={setReferenceCheck} anonymisedPeerReviewCheck={anonymisedPeerReviewCheck} setAnonymisedPeerReviewCheck={setAnonymisedPeerReviewCheck} setReadValidationChecklist={setReadValidationChecklist} readPolicyCheck={readPolicyCheck} setReadPolicyCheck={setReadPolicyCheck} articleTitle={articleTitle} setArticleTitle={setArticleTitle} journalTitle = {journal?.journalTitle} setStepOneInfo={setStepOneInfo}/>}
						{selectedTab === 2 && <RemirrorEditorStep manuscriptId={manuscriptId} resetError={resetError} templateType={journal.templateType} isDisabled={isDisabled} setIsDisabled={setIsDisabled} journalId={journal.journalId} saveManuscript={saveAndExit} editorData={editorData} setEditorData={setEditorData} loading={loading} setLoading={setLoading} showModal={showModal} hideModal={hideModal} fetchData={fetchData} setApiCount={setApiCount} showToolbar={showToolbar} setShowToolbar={setShowToolbar} loader={isDataUpdating} setUpdating={setIsDataUpdating} editorChanged={editorChanged} setEditorChanged={setEditorChanged} saveAndContinue={saveAndContinue} manuscriptStatus = {journal?.manuscriptStatus}/>}
						{selectedTab === 3 && <StepTwoForm manuscriptId={manuscriptId} resetError={resetError} handelError={setErrorMessage} journalId={journal.journalId} handleSave={setSaving} referenceType={referenceType} setReferenceType={setReferenceType} />}
						{selectedTab === 4 && <StepThreeForm manuscriptId={manuscriptId} handelError={setErrorMessage} journalId={journal.journalId} mandatoryFieldSet={mandatoryFieldSet} handleMandatoryCheck={handleMandatoryCheck} />}
						{selectedTab === 5 && <StepFourForm manuscriptId={manuscriptId} handelError={setErrorMessage} journalId={journal.journalId} handleSave={setSaving} mandatoryFieldSet={mandatoryFieldSet} handleMandatoryCheck={handleMandatoryCheck} defaultTab = {defaultAuthorTab}/>}
						{selectedTab === 6 && <StepFiveForm manuscriptId={manuscriptId} handelError={setErrorMessage} journalId={journal.journalId} handleEdit={handleSelect} stepStatus={stepStatus} step={tabIndex} editorChanged={editorChanged} setEditorChanged={setEditorChanged}/>}
					</section>
				</div>
			</Col>
			<Col md={4} className=" border-left pl-0">
				<div className="border-bottom sticky-div-2">

					<ActionView
						errorMessage={errorMessage}
						handleSave={handleSaveStep}
						handleValidate={handleNextStep}
						manuscriptId={manuscriptId}
						step={tabIndex}
						loader={loader}
						handleSkipError={handleSkipStep}
						handleStepStatus={handleStepStatus}
						handleSkipStep={handleSkipStep}
						isSkiped={isSkiped}
						journalTitle={journal.journalTitle}
						setErrorMessage={setErrorMessage}
						type2Error={type2Error}
						type3Error={type3Error}
						type4Error={type4Error}
						type5Error={type5Error}
						type6Error={type6Error}
						setType2Error={setType2Error}
						setType3Error={setType3Error}
						setType4Error={setType4Error}
						setType5Error={setType5Error}
						setType6Error={setType6Error}
						typeAutoCorrect={typeAutoCorrect}
						setTypeAutoCorrect={setTypeAutoCorrect}
						typeAuthor={typeAuthor}
						setTypeAuthor={setTypeAuthor}
						typeIndexTerms = {typeIndexTerms}
              			setTypeIndexTerms = {setTypeIndexTerms}
						setSkipStepTwoError={setSkipStepTwoError}
						isDisabled={isDisabled.isSaveAndValidate}
						fetchData={fetchData}
						apiCount={apiCount}
						setApiCount={setApiCount}
						visibleSkipErrorBtn={visibleSkipErrorBtn}
						skipStepTwoError={skipStepTwoError}
						skipAndContinue={skipAndContinue}
						setShowToolbar={setShowToolbar}
						showToolbar={showToolbar}
						setShouldAllowNavigation = {setShouldAllowkNavigation}
						shouldAllowNavigation = {shouldAllowNavigation}
						setListError={setListError}
						listError={listError}
						exitFunction = {exitFunction}
						setLoader = {setLoader}
						editorChanged={editorChanged} 
						setEditorChanged={setEditorChanged}
					/>
				</div>
			</Col>
			{/* Confirmation modal for skipping a step with warnings & errors */}
			<Modal show={skipConfirmationModal.status} size="lg"  dialogClassName="" onHide={() => {
				setSkipConfirmationModal({...skipConfirmationModal, status: false})
				setLoader({...loader, skipBtnLoader: false})
			}}>
				<Modal.Header  className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0 ">
					<p className="mb-1">Are you sure you want to continue without correcting the below errors? </p>
					{editorChanged && 
						<div className="d-flex flex-row align-items-center pb-2">
							<FontAwesomeIcon icon={faExclamationTriangle} className="text-warning pr-2 h4"/>
							<p className="text-danger p-0 m-0">If you have made any changes to your document onscreen, please ensure you save them by clicking 'Save and check' before moving to Step Three.</p>
						</div>
					}
					<section className="errorModal">
						{typeAuthor?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={typeAuthor.header} details={typeAuthor.details} /> : null}
						{errorMessage?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={errorMessage.header} details={errorMessage.details} /> : null}
						{typeIndexTerms?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={typeIndexTerms.header} details={typeIndexTerms.details} /> : null}
						{type2Error?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type2Error.header} details={type2Error.details} /> : null}
						{type3Error?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type3Error.header} details={type3Error.details} /> : null}
						{type4Error?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type4Error.header} details={type4Error.details} /> : null}
						{type5Error?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type5Error.header} details={type5Error.details} /> : null}
						{type6Error?.details?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type6Error.header} details={type6Error.details} /> : null}
					</section>
					<section className="d-flex justify-content-end align-items-center gap1">
					<button className="btnOutline mt-2 w-200" 
						onClick={(e) => {
							e.preventDefault()
							setSkipConfirmationModal({...skipConfirmationModal, status: false})
							setLoader({...loader, skipBtnLoader: false})
						}}
					>Cancel</button>
						{!loader.skipBtnLoader && <button onClick={() => {handleSkipStep(skipConfirmationModal.index)}} className="w-200 mt-2">Skip Errors</button>}
						{loader.skipBtnLoader && <FieldLoader className=" btn-info btn mt-2 w-200" />} 
					</section>
				</Modal.Body>
        	</Modal>

			{/* Modal to make user aware about step completion */}
			<Modal show={stepCompleteModal} dialogClassName="" onHide={() => {setStepCompleteModal(false)}}>
				<Modal.Header closeButton className="h6">Step Accomplished!</Modal.Header>
				<Modal.Body>
					<p>Congratulations, you've successfully completed this step!</p>
					<p>All errors and warnings have either been addressed or ignored. Would you like to proceed to the next step?</p>
					{/* <p>Here is the list of passed validations</p> */}
					{/* <section className="errorModal"> */}
						{/* {typeAutoCorrect && <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={typeAutoCorrect.header} details={typeAutoCorrect.details} />} */}
						{/* {errorMessage?.passList?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={errorMessage.header} details={errorMessage?.passList} passList={true} /> : null}
						{type2Error?.passList?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type2Error.header} details={type2Error?.passList} passList={true}/> : null}
						{type3Error?.passList?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type3Error.header} details={type3Error?.passList} passList={true}/> : null}
						{type4Error?.passList?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type4Error.header} details={type4Error?.passList} passList={true}/> : null}
						{type5Error?.passList?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type5Error.header} details={type5Error?.passList} passList={true}/> : null}
						{type6Error?.passList?.length ? <ErrorSection fetchData={fetchData} setErrorMessage={setErrorMessage} header={type6Error.header} details={type6Error?.passList} passList={true}/> : null}
					</section> */}
					{/* <section className="d-flex justify-content-end align-items-center gap1"> */}
						<button className="w-100 btn-outline" onClick={() => {setStepCompleteModal(false)}}>No</button>
						<button className="mt-2 w-100" onClick={() => {
							setSelectedTab(tabIndex+1)
							history.push(`/manuscript-submission/${manuscriptId}?step=${tabIndex + 1}`)
							setStepCompleteModal(false)
						}}>
							Yes
						</button>
					{/* </section> */}
				</Modal.Body>
			</Modal>
			
			{/* Local navigation warning */}
			<Modal show={localNavigationWarningModal.visible} dialogClassName="" onHide={() => {setLocalNavigationWarningModal({visible: false, step: 0})}}>
				<Modal.Header closeButton className="h6">Warning!</Modal.Header>
				<Modal.Body>
					<div className="d-flex flex-row align-items-center pb-2">
						<FontAwesomeIcon icon={faExclamationTriangle} className="text-warning pr-2 h4"/>
						<p className="text-danger p-0 m-0">If you have made any changes to your document onscreen, please ensure you save them by clicking '{renderSaveAndValidateButtonText(2)}' before moving to Step {localNavigationWarningModal.step}.</p>
					</div>
					<section className="d-flex justify-content-end align-items-center gap1">
						<button className="btnOutline mt-2" 
							onClick={(e) => {
								setEditorChanged(false)
								resetError()
								setErrorList([])
								setSelectedTab(localNavigationWarningModal.step)
								setLocalNavigationWarningModal({visible: false, step: 0})
								history.push(`/manuscript-submission/${manuscriptId}?step=${localNavigationWarningModal.step}`)
							}}
						>
							Continue
						</button>
						<button className="mt-2" onClick={() => {
							setLocalNavigationWarningModal({visible: false, step: 0})
						}}>
							Close
						</button>
					</section>
				</Modal.Body>
			</Modal>

			{/* Read policy modal for step one */}
			<Modal size="lg" show={readPolicyModal.visible} onHide={() => {setReadPolicyModal({...readPolicyModal, visible: false, loading: false})}}>
				<Modal.Header>Warning!</Modal.Header>
				<Modal.Body>
					<label className="d-flex justify-content-between align-items-center mt-1">
						Validate compliance by reviewing and confirming our policies
					</label>
					<div className="rounded border p-2">
						<table className="mb-2 w-100">
							<tr>
								<td className="p-2">
									<a target="_blank" href="https://www.rsc.org/journals-books-databases/author-and-reviewer-hub/process-and-policies/#prior-publication">
										<i>Journal policy on prior publication</i>
										<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
									</a>
								</td>
								<td className="p-2">
									<a target="_blank" href="https://www.rsc.org/journals-books-databases/author-and-reviewer-hub/authors-information/prepare-and-format/experimental-reporting-requirements/#experimentaldata">
										<i>Experimental data policy</i>
										<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
									</a>
								</td>
							</tr>
							<tr>
								<td className="p-2">
									<a target="_blank" href="https://www.rsc.org/journals-books-databases/author-and-reviewer-hub/authors-information/responsibilities/#Safety-and-hazards">
										<i>Guidelines on safety & hazards</i>
										<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
									</a>
								</td>
								<td className="p-2">
									<a target="_blank" href="https://www.rsc.org/journals-books-databases/author-and-reviewer-hub/authors-information/responsibilities/#human-animal-welfare">
										<i>Human and animal welfare policy</i>
										<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
									</a>
								</td>
							</tr>
							<tr>
								<td className="p-2">
									<a target="_blank" href="https://www.rsc.org/journals-books-databases/author-and-reviewer-hub/authors-information/responsibilities/#dual-use-research">
										<i>Dual Use Research of Concern policy</i>
										<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
									</a>
								</td>
								<td className="p-2">
									<a target="_blank" href="https://www.rsc.org/journals-books-databases/author-and-reviewer-hub/authors-information/prepare-and-format/data-sharing/">
										<i>Data sharing policy</i>
										<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
									</a>
								</td>
							</tr>
							<tr>
								<td className="p-2">
									<a target="_blank" href="https://www.rsc.org/journals-books-databases/author-and-reviewer-hub/authors-information/prepare-and-format/experimental-reporting-requirements/">
										<i>Experimental reporting requirements</i>
										<FontAwesomeIcon icon={faExternalLink} className="ml-2"/>
									</a>
								</td>
							</tr>
						</table>
						<div className="d-flex flex-column justify-content-between">
							<div className="d-flex mb-2">
								<input className="mr-2" type="checkbox" name="journalPolicyCheck" value={readPolicyCheck} onChange={(e) => setReadPolicyCheck(e.target.checked ? "yes" : "no")} checked={readPolicyCheck === "yes"}/>
								I can confirm I have read the policies and comply with the journal's requirements
							</div>
						</div>
					</div>
					<section className="d-flex justify-content-end align-items-center gap1">
						<button className="btnOutline mt-2" 
							onClick={() => {
								setReadPolicyModal({visible: false, loading: false})
							}}
						>
							Close
						</button>
						<button className="mt-2" onClick={(e) => {
							e.preventDefault()
							if(readPolicyCheck === "yes") {
								handleSaveStep(tabIndex)
								setReadPolicyModal({visible: false, loading: false})
							} else {
								toast.error("Please select the checkbox to agree to our policy.")
							}
						}}>
							Save
						</button>
					</section>
				</Modal.Body>
			</Modal>

			{/* Journal config cehcklist */}
			<Modal keyboard={journalConfigModal.closable ? true : false} size="xl" show={journalConfigModal.visible} backdrop={journalConfigModal.closable ? true : "static"} onHide={() => {setJournaLConfigModal({...journalConfigModal, visible: false, loading: false})}} onShow={() => {getReadingCheckList()}} dialogClassName="modal-dialog-scrollable">
				<Modal.Header closeButton={journalConfigModal.closable} className="h6">Journal Configuration Checklist</Modal.Header>
				<Modal.Body>
					<Accordion defaultActiveKey="0" activeKey={journalConfigAccordianKey}>
						{journalConfigChecklist?.sections ? 
							Object.keys(journalConfigChecklist.sections).map((configHeader, headerIndex) => {
								let emptyValueCount = getEmptyCount(configHeader)
								let rowCount = 0
								if(journalConfigChecklist.sections[configHeader].length - emptyValueCount > 0) {
									return (
										<Card>
											<Accordion.Toggle as={Card.Header} eventKey={headerIndex.toString()}>
												<div className="d-flex flex-row justify-content-between align-items-center" onClick={() => {
													if(journalConfigAccordianKey === headerIndex.toString()) {
														setJournalAccordianKey("-1")
													} else {
														setJournalAccordianKey(headerIndex.toString())
													}
												}}>
													<p className="p-0 m-0">{configHeader} ({journalConfigChecklist.sections[configHeader].length - emptyValueCount})</p>
													{journalConfigAccordianKey === headerIndex.toString() ? 
														<FontAwesomeIcon icon={faChevronDown}/>
													:
														<FontAwesomeIcon icon={faChevronUp}/>
													}
												</div>
											</Accordion.Toggle>
											<Accordion.Collapse eventKey={headerIndex.toString()}>
												<Card.Body>
													<table className="table">
														<thead>
															<tr>
																<th scope="col" width="5%">S.no</th>
																<th scope="col" width="30%">Field Name</th>
																<th scope="col" width="55%">Values</th>
															</tr>
														</thead>
														<tbody>
															{journalConfigChecklist.sections[configHeader]?.map((item) => {
																if(renderJournalConfigValue(item.value)) {
																	rowCount += 1
																	return (
																		<tr>
																			<th scope="row">{rowCount}</th>
																			<td dangerouslySetInnerHTML={{__html: item.name}}></td>
																			<td dangerouslySetInnerHTML={{__html: renderJournalConfigValue(item.value)}}></td>
																		</tr>			
																	)
																}
															})}
														</tbody>
													</table>
												</Card.Body>
											</Accordion.Collapse>
										</Card>
									)
								}
							})
						:
							<p className="text-center">Not able to find config checklist! Please refresh the page and try again.</p>
						}
					</Accordion>
				</Modal.Body>
				<Modal.Footer className="d-flex flex-row justify-content-between">
					<span>
						<input disabled={journalConfigModal.loading || journalConfigModal.closable} className="mr-2" type="checkbox" checked={readValidationChecklist} onChange={(e) => {setReadValidationChecklist(e.target.checked)}}/> 
						I confirm that I have read the journal configuration
					</span>
					<button className="px-5" disabled={journalConfigModal.loading} onClick={() => {
						if(!journalConfigModal.closable && readValidationChecklist) {
							handleValidationChecklistContinue()
						} else {
							if(journalConfigModal.closable) {
								setJournaLConfigModal({...journalConfigModal, visible: false, loading: false})
							} else {
								toast.error("Please read the journal configuration checklist and click on confirm.", {autoClose: 3000})
							}
						}
					}}>
						{journalConfigModal.loading ? 
							<Spinner animation="grow" size="sm"/>
						:
							journalConfigModal.closable ? "Close" : "Proceed"	
						}
					</button>
				</Modal.Footer>
			</Modal>
		</Row>
		</div>
	)
}

const ErrorSection = ({fetchData, setErrorMessage, header, details, passList}) => {
	const [show, hide] = useState(false)
	return (
		<><h6 className="bg-light p-2 flex-between border-top border-bottom cursor-pointer" onClick={()=> hide(v=> !v)}><span>{header? header: "Validation errors and warnings"} ({details?.length})</span><FontAwesomeIcon icon={!show ? faAngleDown : faAngleUp} /></h6>
			{show && <ul className="mx-0 px-2">{details?.map((h , index)=> 
				<li className="my-2">
					{
						h.dataType ==="Array"?
							<ErrorItem err={h} index={index} fetchData={fetchData} setErrorMessage={setErrorMessage} header={header}>
							</ErrorItem>
						: 
							h?.newText ? 
								<p className="mb-0">
									<p>
										<span className={h.type==="error"?"text-danger text-capitalize font-weight-bold":"text-warning text-capitalize font-weight-bold"}>Error: </span>
										<span dangerouslySetInnerHTML={{ __html: `${h?.oldText}` }}></span>
									</p>
									<p className="mb-0">
										<span className={"font-weight-bold text-success"}>Corrected text: </span>
										<span dangerouslySetInnerHTML={{ __html: `${h?.newText}` }}></span>
									</p>
								</p>
							:
								passList ? 
									<p className="mb-0">
										<span className={"font-weight-bold text-capitalize text-success"}>Verified: </span>
										<span dangerouslySetInnerHTML={{ __html: `${h.description}` }}></span>
									</p>
								:
									<p className="mb-0">
										<span className={h.type==="error"?"text-danger text-capitalize font-weight-bold":"text-warning text-capitalize font-weight-bold"}>{h.type}: </span>
										<span dangerouslySetInnerHTML={{ __html: `${h.description}` }}></span>
									</p>
					}
				</li>
				)}
			</ul>}
		</>
	)
}

export default MultiStepTabView
