import React, { useState } from 'react'
import HtmlInput from './HtmlInput'
import { Card, Col, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import styles from '../JournalSearch/JournalSearch.module.scss';
import { toast } from 'react-toastify'
import AxiosService from '../../utils/AxiosService'
import classNames from 'classnames'
import { CircleLoader } from '../../components/Loader/CommonLoader'

const JournalGuidelines = ({ adminGuidelinesInfo, IsInfoLoading, getAdminGuidelinesInfo }) => {
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [status, setStatus] = useState("Manuscript ");
    const apiService = new AxiosService();

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (Object.keys(formValues).length === 0) {
            event.stopPropagation();
            toast.warning("No changes, please make changes", {
                autoClose: 3000,
            })
        } else {
            if (form.checkValidity() === false) {
                event.stopPropagation();
                toast.warning("Please fill out all required fields.", {
                    autoClose: 3000,
                })
            } else {
                setIsSaveLoading(true)
                apiService.saveAdminGuidelinesInfo(adminGuidelinesInfo.projectId, formValues).then((res) => {
                    toast.success("Saved successfully", {
                        autoClose: 3000,
                    })
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth"
                    });
                    getAdminGuidelinesInfo();
                    setIsSaveLoading(false);
                    setFormValues({})
                }).catch(err => {
                    setIsSaveLoading(false);
                    toast.error(err.response.data.message, {
                        autoClose: 3000,
                    })
                })
            }
        }
    }

    function infoFunction(manuscriptText) {
        const parts = manuscriptText.split(/<b[^>]*>/).slice(1);
        const content =manuscriptText.split(":");
        return { title: parts[1].split(":")[0], content: content[1] }
    }
    const handleArrayChange = (attribute, value) => {
        const array = [...adminGuidelinesInfo.guidelines]
        for (let i = 0; i < array.length; i++) {
            if (array[i].includes(attribute)) {
                array[i] = `<br><b>${attribute}:</b> ${value}`;
                break;
            }
        }
        setFormValues({ "fullPapers": array })
    }
    return (
        <Row className='my-3 mx-2'>
            <div className=''>
            <h5 className='mb-2'>Journal Guidelines</h5>
            <p>Edit the journal guidelines for each of the mandatory manuscript section</p>
            </div>
            {
                IsInfoLoading ? <Col className='text-center'><CircleLoader /></Col> : <>
                    <Card>
                        <Card.Body className=''>
                            <Row>
                                <Col md={12} className="tabSection">
                                    <Tabs
                                        activeKey={status}
                                        onSelect={(k) => setStatus(k)}
                                        className={`${styles.customTab}`}
                                    >
                                        {
                                            adminGuidelinesInfo.guidelines.map((info, i) =>
                                                <Tab eventKey={infoFunction(info).title} title={infoFunction(info).title} tabClassName={classNames(status === infoFunction(info).title ? styles.customActiveTab : styles.customTab)} key={i}>
                                                </Tab>
                                            )
                                        }
                                    </Tabs>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <div className='w-100 mt-3'>
                        {adminGuidelinesInfo.guidelines.map((info, i) =>
                            status === infoFunction(info).title && <HtmlInput rules={{ name: "Journal guidelines", attribute: infoFunction(info).title }} handleArrayChange={handleArrayChange} intialValue={infoFunction(info).content} />
                        )
                        }
                        {
                            !isSaveLoading ? <button onClick={handleSubmit} className='my-3'>Save Journal Guidelines</button> : (
                                <button disabled={isSaveLoading} className='my-3'>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Saving...</span>
                                </button>)
                        }
                    </div></>
            }


        </Row>

    )
}

export default JournalGuidelines
